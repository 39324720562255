import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageContainerComponent } from './page-container/page-container.component';
import { PageSideComponent } from './page-side/page-side.component';
import { PageContentComponent } from './page-content/page-content.component';
import { PageTitleInsideComponent } from './page-title-inside/page-title-inside.component';
import { PageTitleInsideSecondComponent } from './page-title-inside-second/page-title-inside-second.component';
import { AsntMtIconModule } from '../../asnt-icon/asnt-mt-icon/asnt-mt-icon.module';
import { PageListRuleComponent } from './page-list-rule/page-list-rule.component';
import { PageTitleInsideThirdComponent } from './page-title-inside-third/page-title-inside-third.component';
import { PageImageComponent } from './page-image/page-image.component';

@NgModule({

  declarations: [
    PageContainerComponent,
    PageSideComponent,
    PageContentComponent,
    PageTitleInsideComponent,
    PageTitleInsideSecondComponent,
    PageTitleInsideThirdComponent,
    PageListRuleComponent,

  ],
  imports: [
    CommonModule,
    AsntMtIconModule,
    PageImageComponent,
  ],
  exports: [
    PageContainerComponent,
    PageSideComponent,
    PageContentComponent,
    PageTitleInsideComponent,
    PageTitleInsideSecondComponent,
    PageTitleInsideThirdComponent,
    PageListRuleComponent,
    PageImageComponent,
  ]
})
export class PageInnerStructureModule { }