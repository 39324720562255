@if (thin) {
<svg width="25" height="25" [style]="'width:'+ size + '; height:' + size + ';'" viewBox="0 -960 960 960">
    <path [style]="'fill:'+ color"
        d="m254-238-16-16 226-226-226-226 16-16 226 226 226-226 16 16-226 226 226 226-16 16-226-226-226 226Z" />
</svg>
} @else {
<svg width="25" height="25" [style]="'width:'+ size + '; height:' + size + ';'" viewBox="0 96 960 960">
    <path [style]="'fill:'+ color"
        d="m249 849-42-42 231-231-231-231 42-42 231 231 231-231 42 42-231 231 231 231-42 42-231-231-231 231Z" />
</svg>
}