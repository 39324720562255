import { Component, HostBinding } from '@angular/core';

@Component({
  selector: 'page-title-inside-third',
  templateUrl: './page-title-inside-third.component.html',
  styleUrl: './page-title-inside-third.component.less'
})
export class PageTitleInsideThirdComponent {

  @HostBinding('class') get hostClasses(): string {
    return `page-title-inside-third`;
  }

}
