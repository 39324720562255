import { CommonModule } from '@angular/common';
import { Component, HostBinding } from '@angular/core';
import { AsntIconBasedComponent } from '../../asnt-icon-based/asnt-icon-based.component';

@Component({
  selector: 'mt-icon-expand-more',
  standalone: true,
  imports: [
    CommonModule,
  ],
  templateUrl: './mt-icon-expand-more.component.html',
  styleUrl: './mt-icon-expand-more.component.less'
})
export class MtIconExpandMoreComponent extends AsntIconBasedComponent {


  @HostBinding('class') get hostClasses(): string {
    return `asnt-mt-icon asnt-mt-icon-expand-more`;
  }

}
