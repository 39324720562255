import { Component, HostBinding } from '@angular/core';

@Component({
  selector: 'page-container',
  templateUrl: './page-container.component.html',
  styleUrl: './page-container.component.less'
})
export class PageContainerComponent {

  
  @HostBinding('class') get hostClasses(): string {
    return `page-container`;
  }
}
