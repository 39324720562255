import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'page-list-rule',
  templateUrl: './page-list-rule.component.html',
  styleUrl: './page-list-rule.component.less'
})
export class PageListRuleComponent {

  @Input() listType!: string;
  

  @HostBinding('class') get hostClasses(): string {
    return `page-list-rule `;
  }
}
