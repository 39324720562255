import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'page-title-inside-second',
  templateUrl: './page-title-inside-second.component.html',
  styleUrl: './page-title-inside-second.component.less'
})
export class PageTitleInsideSecondComponent {

  @Input() marginBottom: string = '50px';
  @Input() marginTop: string = '20px';

  @HostBinding('class') get hostClasses(): string {
    return `page-title-inside-second`;
  }

  @HostBinding('style.margin-bottom') get hostMarginBottom(): string {
    return this.marginBottom;
  }

  @HostBinding('style.margin-top') get hostMarginTop(): string {
    return this.marginTop;
  }

}
