import { Component, HostBinding } from '@angular/core';

@Component({
  selector: 'page-content',
  templateUrl: './page-content.component.html',
  styleUrl: './page-content.component.less'
})
export class PageContentComponent {

  @HostBinding('class') get hostClasses(): string {
    return `page-content`;
  }

}
