import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'page-title-inside',
  templateUrl: './page-title-inside.component.html',
  styleUrl: './page-title-inside.component.less'
})
export class PageTitleInsideComponent {

  @Input() no!: number;

  @HostBinding('class') get hostClasses(): string {
    return `page-title-inside `;
  }

}
