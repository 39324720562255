import { Component, HostBinding } from '@angular/core';


@Component({
  selector: 'page-side',
  templateUrl: './page-side.component.html',
  styleUrl: './page-side.component.less'
})
export class PageSideComponent {


  @HostBinding('class') get hostClasses(): string {
    return `page-side `;
  }



}
