import { Component, Input } from '@angular/core';

@Component({
  selector: 'asnt-icon-based',
  template: '',
})
export class AsntIconBasedComponent {
  @Input() size: string = '48px';
  @Input() color: string = '#666';
  @Input() thin: boolean = false;
}
