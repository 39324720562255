import { Component } from '@angular/core';
import { AsntIconBasedComponent } from '../../asnt-icon-based/asnt-icon-based.component';

@Component({
  selector: 'mt-icon-menu',
  standalone: true,
  imports: [],
  templateUrl: './mt-icon-menu.component.html',
  styleUrl: './mt-icon-menu.component.less'
})
export class MtIconMenuComponent extends AsntIconBasedComponent {



}
